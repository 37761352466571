import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dealerFiltersDuck } from '../ducks';
import { useCookies } from 'react-cookie';
import PersonalizationEngineModule from '../modules/PersonalizationEngineModule';

const PersonalizationEngineContainer = ({ pageName, brand }) => {
    const dispatch = useDispatch();
    const filterData = useSelector(dealerFiltersDuck.selectors.getFiltersValues);
    const [cookies = {}] = useCookies(['ATC_USER_ZIP', 'auth_consumer_id']);

    useEffect(() => {
        const zipCode = filterData?.zip ?? cookies.ATC_USER_ZIP;
        const consumerId = cookies.auth_consumer_id || '';
        const query = {
            'pc.origin': pageName || '',
            bu: brand,
            catalog: brand,
            consumerId,
        };

        dispatch(PersonalizationEngineModule.duck.creators.fetchClientPersonalization(zipCode, query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default React.memo(PersonalizationEngineContainer);
