/* global window */
import { colors, Section } from '@argo/principles';
import getConfig from 'next/config';
import styled from '@emotion/styled';
import { get as _get } from '../../utilities/common';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useBrand } from 'reaxl-brand';
import { useDevice } from '@atc/react-device';
import { TAB_NAME, atcBrand, kbbBrand, dynamicEmbeddedWidgetExpanderHeightValue } from '../../constants';
import { getSelectedTabFromState } from '../../utilities';
import { setWidgetFont, getDevicePadding } from '../../utilities/helpers';
import { dealerDetailOriginDuck, inventoryActiveOwnerDuck } from '../../ducks';

const setBackground = (isReview) => `background-color: ${isReview ? colors.neutral.offWhite : colors.neutral.white};`;

const EmbeddedWidgetSectionContainer = styled(Section)`
    position: relative;
    &::after {
        content: '';
        width: 100vw;
        position: absolute;
        height: 100%;
        top: 0;
        transform: translate(-50%, 0);
        z-index: 1;
        ${({ isReview }) => setBackground(isReview)}
        left: 49%;
    }
    >*{
        position: relative;
        z-index: 2;
    }
`;

const SpanText = styled.span`
    ${({ isKbb }) => setWidgetFont(isKbb)}
    color: #000000;
    line-height: 1;
    margin-bottom: 24px;
`;

const EmbeddedWidget = ({ parentTab, ownerId, selectedTab, originPath }) => {
    const iframeId = `embeddedWidget-${parentTab}-tab`;
    const { publicRuntimeConfig } = getConfig();
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const { kbbRatingEmbeddedUrl } = publicRuntimeConfig;
    const [widgetHeight, setWidgetHeight] = useState('270px');
    const [seeAllReviewsCtaUrlParam, setSeeAllReviewsCtaUrlParam] = useState('');
    const isReviewTab = parentTab === TAB_NAME.REVIEWS;
    const theme = isReviewTab ? 'light' : 'dark';
    const isInverse = isReviewTab ? '&isinverse=true' : '';
    const widgetPadding = getDevicePadding(isXs);
    const brandName = isKbb ? kbbBrand : atcBrand;

    const sendMessageCallback = useCallback(() => {
        window.addEventListener('message', (event) => {
            const { data } = event;
            const childWindow = document.getElementById(iframeId).contentWindow;
            if (event.source === childWindow && data.height > 0) {
                const isEmbeddedWidget = data.eventSource === 'EmbeddedWidget' && data.eventAction === 'heightChanged';
                const isEmbeddedWidgetGoogleRatingSection = data.eventSource === 'EmbeddedWidgetGoogleRatingSection' && data.eventAction === 'heightChanged';
                if (isEmbeddedWidget) {
                    setWidgetHeight(`${data.height}px`);
                } else if (isEmbeddedWidgetGoogleRatingSection) {
                    setWidgetHeight(`${data.height + dynamicEmbeddedWidgetExpanderHeightValue}px`);
                }
            }
        });

    }, []);

    useEffect(() => {
        if (!isReviewTab) {
            const { origin, pathname, search } = window.location;
            const searchParams = new URLSearchParams(search);
            if (searchParams.get('dealerdetailstabs') || window.location.search.split('#')[1]) {
                searchParams.set('dealerdetailstabs', TAB_NAME.REVIEWS);
            } else {
                searchParams.append('dealerdetailstabs', TAB_NAME.REVIEWS);
            }
            const encodeUrl = encodeURIComponent(`${origin}${pathname}?${searchParams.toString()}#${TAB_NAME.REVIEWS}`);
            setSeeAllReviewsCtaUrlParam(`&seeallreviewsctaurl=${encodeUrl}`);
        }
    }, []);

    useEffect(() => {
        sendMessageCallback();
        return () => window.removeEventListener('message', sendMessageCallback());
    }, [ownerId, selectedTab]);

    return (
        <EmbeddedWidgetSectionContainer
            fullWidth
            id="embeddedWidgetContainer"
            data-cmp="embeddedWidgetSection"
            isReview={isReviewTab}
            horizontalSpacingOverrides={{ left: widgetPadding, right: widgetPadding }}
            verticalSpacingOverrides={{ top: isReviewTab ? '0' : '40px', bottom: '40px' }}
            bgColor={isReviewTab ? `${colors.neutral.offWhite}` : colors.neutral.white}
        >
            <SpanText isKbb={isKbb}>Ratings &amp; Reviews</SpanText>
            <iframe
                id={iframeId}
                width="100%"
                height={widgetHeight}
                title="Dealer Rating Badge"
                scrolling="yes"
                frameBorder="0"
                src={ownerId && `${kbbRatingEmbeddedUrl}&svocId=${ownerId}&theme=${theme}${seeAllReviewsCtaUrlParam}&origin=${originPath}&pageName=ddp&siteconfig=${brandName}${isInverse}`}
                loading="lazy"
            />
        </EmbeddedWidgetSectionContainer>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state, `owners.${activeOwner}`, {});
    const ownerId = owner.dealerId && owner.dealerId > 0 ? owner.dealerId : owner.id;
    const selectedTab = getSelectedTabFromState(state);
    const originPath = dealerDetailOriginDuck.selectors.getDuckState(state);

    return {
        ownerId,
        selectedTab,
        originPath,
    };
}
export default connect(mapStateToProps)(EmbeddedWidget);
