import React, { memo } from 'react';
import { connect } from 'react-redux';
import { get as _get } from '../../../utilities/common';
import DealerHomeServices from '../../../components/dealerHomeServices';
import { TAB_NAME } from '../../../constants';
import { getSelectedTabFromState } from '../../../utilities';
import { inventoryActiveOwnerDuck } from '../../../ducks';

// this function to keep the maintenanceValet value unchange on service tab
const disableServiceTabDHS = (services) => {
    Object.keys(services).forEach((key) => {
        if (key !== 'maintenanceValet' && services[key]) {
            services[key] = !services[key];
        }
    });
};

export const DealerHomeServicesContainer = memo(({
    homeServices = {},
    accelerateDealer = false,
    brand,
    isServiceTab,
    homeServicesForServiceTab,
}) => {
    if (!Object.keys(homeServices).length) {
        return null;
    }

    disableServiceTabDHS(homeServicesForServiceTab);

    const props = {
        services: {
            ...(isServiceTab ? homeServicesForServiceTab : homeServices),
            accelerate: accelerateDealer,
        },
        brand,
        isServiceTab,
    };

    return (
        <DealerHomeServices {...props} />
    );
});

const mapStateToProps = (state) => {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const brand = _get(state, 'brand', '');
    const selectedTab = getSelectedTabFromState(state);

    const homeServices = _get(state.owners, `${activeOwner}.homeServices`, {});
    const homeServicesForServiceTab = { ...homeServices };
    const isServiceTab = selectedTab?.name === TAB_NAME.SERVICE;

    return {
        homeServices,
        accelerateDealer: owner.accelerateDealer || false,
        brand,
        isServiceTab,
        homeServicesForServiceTab,
    };
};

export default connect(mapStateToProps)(DealerHomeServicesContainer);
