import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DealerDetailsContainer, DealerDetailsPathLocationContainer, DealerDetailsBottomNavContainer } from './dealerdetails';
import { get as _get } from '../utilities/common';
import { ContactDealerContainer } from './dealerdetails/contactdealer/index';
import { Section, GridContainer, colors } from '@argo/principles';
import { inventorySupportMakesDuck, inventoryActiveOwnerDuck, dealerTabsDuck } from '../ducks';

// fld and ddp share the owners state, that causes some problems on kbb branch,
// so we distinguish by franchiseName, just fld kbb have franchiseName
const DealerDetailsNewFeature = React.memo(({ owner = {}, makes, tabs }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(tabs.length <= 0);
    }, [tabs]);

    return owner && (
        <Section
            fullWidth
            id="dealerDetailsNewSection"
            data-cmp="dealerDetailsNewSection"
            withGlobalNavSpacing
            className="section-main-bg"
            horizontalSpacingOverrides={{ left: '0', right: '0' }}
            verticalSpacingOverrides={{ top: '0', bottom: '0' }}
        >
            <GridContainer gap="md">
                <DealerDetailsContainer isLoading={isLoading} />
            </GridContainer>
            {!isLoading && (
                <Section
                    data-cmp="contactDealerSection"
                    fullWidth
                    verticalSpacingOverrides={{ top: '40px', bottom: '64px' }}
                    horizontalSpacingOverrides={{ left: '16px', right: '16px' }}
                >
                    <ContactDealerContainer />
                </Section>
            )}
            <Section
                data-cmp="pathLocationSection"
                fullWidth
                bgColor={colors.neutral.offWhite}
                verticalSpacingOverrides={{ top: '40px', bottom: '64px' }}
                horizontalSpacingOverrides={{ left: '16px', right: '16px' }}
            >
                {isLoading ? (<div className="pathLocationPlaceHolder" />) : (
                    <div className="path-location">
                        {makes.length > 0 ? (<DealerDetailsPathLocationContainer makes={makes} />) : ''}
                    </div>
                )}
                <DealerDetailsBottomNavContainer />
            </Section>
        </Section>
    );
});

function mapStateToProps(state) {
    const { owners } = state;
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(owners, activeOwner, {});
    const makes = inventorySupportMakesDuck.selectors.getDuckState(state);
    const tabData = dealerTabsDuck.selectors.getDuckState(state);
    const tabs = _get(tabData, 'tabs', []);

    return {
        owner,
        makes,
        tabs,
    };
}

export default connect(mapStateToProps)(DealerDetailsNewFeature);
