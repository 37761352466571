import React, { memo } from 'react';
import { get as _get } from '../../utilities/common';
import { connect } from 'react-redux';
import { Pagination, Select } from 'reaxl';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { DOMAnimation } from 'atc-js';

import { rebuildDealerDetailsPage } from '../../actions';

import { numRecords as numRecordsOptions } from '../../reference/options';
import { inventoryPaginationDuck, inventoryResultsDuck } from '../../ducks';
import useCreateDealerDetailsPageEvent from '../../hooks/useCreateDealerDetailsPageEvent';
import { sendClick } from 'reaxl-analytics';
import { useRouter } from 'next/router';

const InventoryPaginationContainer = memo(({
    actions,
    currentPage,
    totalPages,
    numRecordsData,
}) => {
    const createDealerDetailsPageEvent = useCreateDealerDetailsPageEvent();
    const router = useRouter();

    const handleNumRecordsChange = async (event) => {
        sendClick('numRecordsDropDownClick', event, { recordAmount: event.target.value });
        actions.setNumRecords(event.target.value);
        const data = await actions.rebuildDealerDetailsPage(router.push);
        createDealerDetailsPageEvent({ ...data, event });
    };

    const handlePageSelect = async (pageNumber, event) => {
        sendClick('paginationClick', event, { pageNumber });
        actions.setCurrentPage(pageNumber);
        const data = await actions.rebuildDealerDetailsPage(router.push);
        createDealerDetailsPageEvent({ ...data, event: { target: { value: pageNumber } } });
        DOMAnimation.jumpToTopOfPage();
    };

    const birfLog = {
        'data-birf-log': 'none',
    };

    return totalPages > 1 && (
        <div className="col-xs-12 display-flex justify-content-center align-items-center flex-wrap margin-bottom-5">
            <Select
                selectClassName="margin-right-3"
                className="margin-0"
                onChange={handleNumRecordsChange}
                {...numRecordsData}
            />
            <Pagination
                activePage={currentPage}
                items={totalPages}
                onSelect={handlePageSelect}
                maxButtons={7}
                ellipsis={false}
                data-birf-log={birfLog}
            />
        </div>

    );
});

function mapStateToProps(state) {
    const results = inventoryResultsDuck.selectors.getDuckState(state);
    const pagination = inventoryPaginationDuck.selectors.getDuckState(state);
    const numRecords = _get(state, 'router.location.query.numRecords', pagination.numRecords);

    return {
        currentPage: pagination.currentPage,
        totalPages: Math.ceil(results.count / numRecords),
        numRecordsData: { ...numRecordsOptions, value: numRecords === 24 ? 25 : numRecords },
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setCurrentPage: inventoryPaginationDuck.creators.setCurrentPage,
    rebuildDealerDetailsPage,
    setNumRecords: inventoryPaginationDuck.creators.setNumRecords,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPaginationContainer);
