import { get as _get } from './common';
import { chatProvider } from '../constants';
import { inventoryActiveOwnerDuck } from '../ducks';

const getPropsToChat = (state, sendClick) => {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const { chat, id: ownerId } = owner;
    let chatProps = {};
    if (chat && typeof chat === 'object' && chat.provider) {
        const { provider = '', providerId, externalOwnerId = '' } = chat;
        const ownerPhone = owner.phone && {
            phone: {
                ...owner.phone,
                isVisible: owner.phone.visible,
            },
        };
        const ownerData = {
            ...owner,
            ...ownerPhone,
        };
        const listing = {
            owner: ownerData,
        };
        const analyticsData = {
            inventoryId: listing.id,
            ownerId,
        };
        const gubagooUrl = provider === chatProvider.providers[1]
            ? chatProvider.gubagooBaseUrl + (externalOwnerId || '')
            : '';

        if (chatProvider.providers.includes(String(provider))) {
            chatProps = {
                placementId: 1032,
                onChatClick: (event) => {
                    if (provider !== chatProvider.providers[2]) {
                        sendClick('ownerChat', event, analyticsData);
                    }
                },
                animate: false,
                gubagooUrl,
                merchantId: ownerId,
                provider,
                providerId,
                listing,
            };
        }
    }
    return chatProps;
};

export default getPropsToChat;
