import React from 'react';
import styled from '@emotion/styled';
import { sendClick } from 'reaxl-analytics';
import { Section, Theme, Row, Col } from '@argo/principles';
import { Link, ChevronLeftIcon, OffsiteIcon } from '@argo/ui-elements';

const DealerDetailsNavigationContainer = styled.div`
    width: 100%;
`;

const SeeAllDealerContainer = styled.div`
    float: left;
`;

const VisitDealerWebsiteContainer = styled.div`
    float: right;
`;

const RightNavHeadingContainer = styled.b`
    margin-right: 5px;
`;

const LeftNavHeadingContainer = styled.b`
    margin-left: 3px;
`;

const DealerDetailsNavigation = ({
    leftNav = { heading: '', url: '' },
    rightNav = { heading: '', url: '', ownerId: '' },
}) => {
    const onWebsiteClick = (event) => {
        sendClick('ownerWebsite', event, { ownerId: rightNav.ownerId });
    };

    return (
        <DealerDetailsNavigationContainer>
            <Theme>
                <Section
                    fullWidth
                    horizontalSpacingOverrides={{ left: '0', right: '0' }}
                    verticalSpacingOverrides={{ top: '24px', bottom: '0' }}
                    bgColor="#F2F2F2"
                >
                    <Row>
                        <Col sm={5}>
                            {leftNav.url && (
                                <SeeAllDealerContainer>
                                    <Link href={leftNav.url}>
                                        <ChevronLeftIcon size="16" />
                                        <LeftNavHeadingContainer>
                                            {leftNav.heading}
                                        </LeftNavHeadingContainer>
                                    </Link>
                                </SeeAllDealerContainer>
                            )}
                        </Col>
                        <Col sm={7}>
                            {rightNav.url && (
                                <VisitDealerWebsiteContainer>
                                    <Link
                                        href={rightNav.url}
                                        target="_blank"
                                        onClick={onWebsiteClick}
                                    >
                                        <RightNavHeadingContainer>
                                            {rightNav.heading}
                                        </RightNavHeadingContainer>
                                        <OffsiteIcon size="16" />
                                    </Link>
                                </VisitDealerWebsiteContainer>
                            )}
                        </Col>
                    </Row>
                </Section>
            </Theme>
        </DealerDetailsNavigationContainer>
    );
};

export default DealerDetailsNavigation;
