import React, { memo } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { FilteredResultsControls } from 'reaxl-filters';
import { Select } from 'reaxl';
import { get as _get } from '../../utilities/common';
import { sendClick } from 'reaxl-analytics';
import { useDevice } from '@atc/react-device';
import {
    inventoryFiltersDuck,
    inventoryPaginationDuck,
    inventorySortDuck,
    inventoryResultsDuck,
} from '../../ducks';

import useCreateDealerDetailsPageEvent from '../../hooks/useCreateDealerDetailsPageEvent';
import { rebuildDealerDetailsPage } from '../../actions';
import { inventorySortOptions } from '../../reference/options';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

const FilteredResultsControlsLayoutWrapper = styled.div`
    .padding-left-3 {
        width: 250px !important;
    }
    @media (max-width: 768px) {
        min-height: 40px;
    }
`;

const InventoryResultControlsContainer = memo(({
    actions,
    resultCount,
    sortValue,
}) => {
    const inventorySortData = {
        ...inventorySortOptions,
        value: sortValue,
    };

    const createDealerDetailsPageEvent = useCreateDealerDetailsPageEvent();
    const router = useRouter();
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const handleSortChange = async (event) => {
        if (event.target) {
            event.persist();
        }
        const sortType = _get(event, 'target.value', null) || event;
        sendClick('sortDropDownClick', event, { sortType });
        actions.setSort(sortType);
        actions.resetPagination();
        await actions.rebuildDealerDetailsPage(router.push);
        createDealerDetailsPageEvent({ event });
    };

    return (
        <FilteredResultsControlsLayoutWrapper>
            {isXs
                ? (
                    <FilteredResultsControls
                        className="justify-content-md-end"
                        onFilterClick={actions.showFilters}
                        onSortByChange={handleSortChange}
                        resultCount={resultCount}
                        sortData={inventorySortData}
                    />
                )
                : (
                    <Select
                        data-cmp="ddp-sorting"
                        className="justify-content-md-end"
                        labelProps=""
                        onChange={handleSortChange}
                        {...inventorySortData}
                    />
                )}
        </FilteredResultsControlsLayoutWrapper>
    );
});

function mapStateToProps(state) {
    return {
        resultCount: inventoryResultsDuck.selectors.getResultsCount(state),
        sortValue: inventorySortDuck.selectors.getDuckState(state),
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setSort: inventorySortDuck.creators.setSort,
    showFilters: inventoryFiltersDuck.creators.showFilters,
    resetPagination: inventoryPaginationDuck.creators.resetPagination,
    rebuildDealerDetailsPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryResultControlsContainer);
