import React from 'react';
import { connect } from 'react-redux';
import { get as _get } from '../../utilities/common';
import { languages, servicesAndAmenitiesBlacklist } from '../../constants';
import DealerDetailsPathLocation from '../../components/dealerDetailsPathLocation';
import { useBrand } from 'reaxl-brand';
import { kbbBrand } from '../../constants/index';
import { inventoryActiveOwnerDuck } from '../../ducks';

const ownerServiceDataProcessing = (ownerServices, isKbb) => {
    const servicesAndAmenities = [];
    const languageResults = [];
    ownerServices.map((element) => {
        const languageindex = languages.findIndex((language) => element?.trim().toLowerCase() === language?.toLowerCase());
        const blackItemIndex = servicesAndAmenitiesBlacklist.findIndex((blackItem) => element?.trim().toLowerCase() === blackItem?.toLowerCase());
        if (languageindex > -1) {
            languageResults.push(element);
        } else if (blackItemIndex === -1) {
            servicesAndAmenities.push(element);
        }
    });

    return [
        { heading: isKbb ? 'Services & Amenities' : 'Services Offered & Features', items: servicesAndAmenities },
        { heading: isKbb ? 'Languages' : 'Languages Spoken', items: languageResults },
    ];
};

const makeDataProcessing = (makes, isKbb) => {
    const makeResults = makes?.map((make) => make.label);
    return [{ heading: isKbb ? 'Makes' : 'Brands', items: makeResults }];
};

const DealerDetailsPathLocationContainer = ({
    ownerServices,
    makes,
}) => {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const ownerServiceData = ownerServiceDataProcessing(ownerServices, isKbb);
    const makeResults = makeDataProcessing(makes, isKbb);
    const props = { data: makeResults.concat(ownerServiceData) };
    return (
        <DealerDetailsPathLocation {...props} />
    );
};

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.ownerServices) === JSON.stringify(nextProps.ownerServices);
}

DealerDetailsPathLocationContainer.displayName = 'DealerDetailsPathLocationContainer';

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const ownerServices = _get(owner, 'ownerServices', []);

    return {
        ownerServices,
    };
}

export default connect(mapStateToProps)(React.memo(DealerDetailsPathLocationContainer, areEqual));
