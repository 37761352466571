import React from 'react';

let countertabSection = 0;
let counterTabContent = 0;

const TabsPlaceHolder = () => (
    <div className="tabPlaceHolder">
        <div className="tabSection">
            {Array(3)
                .fill(countertabSection++)
                .map((value) => (
                    <div
                        key={`initial-skeleton-${value}`}
                        className="tab-section-loading skeleton__loading"
                    >
                        <div className="skeleton__headline skeleton__placeholder" />
                    </div>
                ))}
        </div>
        <div className="tabContent">
            {Array(5)
                .fill(counterTabContent++)
                .map((value) => (
                    <div
                        key={`initial-skeleton-${value}`}
                        className="tab-content-loading skeleton__loading"
                    >
                        <div className="skeleton__headline skeleton__placeholder" />
                    </div>
                ))}
        </div>
    </div>
);

export default TabsPlaceHolder;
